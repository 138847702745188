import { toast, Notification } from 'components/ui'

export const popNotification = ({ type, title, duration, message }) => {
    if (type == "success") {
    toast.push(
        <Notification title={'Reussi'} type={type} duration={duration}>
            {'Votre opération a été effectuée avec succès'}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
    }else{
        toast.push(
            <Notification title={'Erreur'} type={type} duration={duration}>
                {message}
            </Notification>,
            {
                placement: 'top-center',
            }
        )
    }
}
