import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const balancesSlice = createSlice({
    name: 'balances',
    initialState: {
        data: [],
        nomsBalances: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsBalances: (state) => {
            if (state.detailsBalance == null) {
                state.detailsBalance = ''
            } else {
                state.detailsBalance = null
            }
        }
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsBalances.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsBalances.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsBalance = action.payload
        })
        .addCase(fetchDetailsBalances.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchBalances.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchBalances.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchBalances.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            
            .addCase(createBalance.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createBalance.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createBalance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteBalance.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteBalance.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteBalance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchBalances = createAsyncThunk(
    'balances/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.ba.load/typeSoldes?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch balances')
        }
    }
)

// Async thunk action to fetch details from the API
export const fetchDetailsBalances = createAsyncThunk(
    'Balances/fetchDetails',
    async (id) => {
        const data = {'idc':id.id}
        try {
            const Balance = await BaseService.post(
                `/XML/tr.ba.load/get_one_compte`,data
            )
           
            

            return Balance.data
        } catch (error) {
            throw new Error('Failed to fetch Balances')
        }
    }
)
export const createBalance = createAsyncThunk(
    'balances/post',
    
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ba.send/typeOuvrir_compte', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editBalance = createAsyncThunk('balances/edit', 
async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/tr.ba.send/m_compte', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
}
)

export const deleteBalance = createAsyncThunk(
    'balance/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/balances/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.balances.data
export const selectIsLoading = (state) => state.balances.isLoading
export const selectError = (state) => state.balances.error
export const selectIsDeleted = (state) => state.balances.isDeleted
export const selectCurrentPage = (state) => state.balances.currentPage
export const resetDetailsBalances = balancesSlice.actions.resetDetailsBalances
export default balancesSlice.reducer
