import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const employesSlice = createSlice({
    name: 'employes',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsEmployes: (state) => {

            if (state.detailsEmployes == null) {
                state.detailsEmployes = ''
            } else {
                state.detailsEmployes = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsEmployes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsEmployes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsEmployes = action.payload
            })
            .addCase(fetchDetailsEmployes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchEmployes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEmployes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEmployes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createEmploye.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEmploye.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEmploye.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteAvance.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteAvance.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteAvance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEmployes = createAsyncThunk(
    'employes/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/rh.employe.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch employes')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsEmployes = createAsyncThunk(
    'Employes/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }
        try {
            const Employe = await BaseService.post(
                `/XML/rh.employe.load/get_one_employe`, data
            )
            const toutsFiches = await BaseService.post(
                `/XML/rh.employe.load/get_fiche_de_paie_one_employe`, data
            )
            const avance = await BaseService.get(
                `/XML/rh.paie.load/get_avance_employe?id=${id.id}`
            )

            const objsexe = {
                'F': 'Femme',
                'H': 'Homme'
            }
            const objstatut = {
                '0': 'EX-Employe',
                '1': 'Employe',
            }
            if (Employe?.data?.employe && typeof id.side !== 'undefined') {
                Employe.data.employe[0]['sexe'] = objsexe[Employe.data.employe[0]['sexe']]
                Employe.data.employe[0]['statut'] = objstatut[Employe.data.employe[0]['statut']]
                Employe.data.employe[0]['avantage'] = formatToDH(Employe.data.employe[0]['avantage'])
                Employe.data.employe[0]['opposition'] = formatToDH(Employe.data.employe[0]['opposition'])
                Employe.data.employe[0]['tauxRetraiteComplemantaire'] = formatToDH(Employe.data.employe[0]['tauxRetraiteComplemantaire'])

            }
            Employe.data.toutsFiches = toutsFiches.data
            Employe.data.avance = avance.data

            return Employe.data
        } catch (error) {
            throw new Error('Failed to fetch Employes')
        }
    }
)


export const createEmploye = createAsyncThunk(
    'employes/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/rh.employe.send/ajouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editEmploye = createAsyncThunk('employes/edit',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/rh.employe.send/modifier', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            popNotification(creation?.error)
        }
    }


)




export const avanceEmploye = createAsyncThunk('employes/avanec',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/rh.paie.send/avance', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            popNotification(creation?.error)
        }
    }


)







export const soldeEmploye = createAsyncThunk('employes/solde',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/rh.employe.send/solde_de_tous_compte', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            popNotification(creation?.error)
        }
    }


)



export const deleteAvance = createAsyncThunk(
    'avance/delete',
    async (payload) => {
        const { id,setClientAded } = payload
        if (!id) return
        try {
            const response = await BaseService.post(`/XML/rh.paie.send/supprimer_avance`, { id })
            if (response.status === 200) {
                popNotification(creation?.success)
                setClientAded(true)
                return true
            }

        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
            return
        }
    }
)



export const selectData = (state) => state.employes.data
export const selectIsLoading = (state) => state.employes.isLoading
export const selectError = (state) => state.employes.error
export const selectIsDeleted = (state) => state.employes.isDeleted
export const selectCurrentPage = (state) => state.employes.currentPage
export const resetDetailsEmployes = employesSlice.actions.resetDetailsEmployes
export const startLoading = employesSlice.actions.startLoading
export const stopLoading = employesSlice.actions.stopLoading
export default employesSlice.reducer
