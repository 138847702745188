import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import formatToDH from 'functions/dhformat'
import { formatDateSend } from '../../functions/formatDateSend'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const VentesSlice = createSlice({
    name: 'ventes',
    initialState: {
        data: [],
        operateurs: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsVente: (state) => {
            if (state.detailsVente == null) {
                state.detailsVente = ''
            } else {
                state.detailsVente = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsVentes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsVentes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsVente = action.payload
            })
            .addCase(fetchDetailsVentes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(fetchContenuNLivreVente.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchContenuNLivreVente.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.contenuNLivre = action.payload
            })
            .addCase(fetchContenuNLivreVente.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(fetchVentes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchVentes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchVentes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(confVente.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(confVente.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(confVente.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })

            .addCase(fetchOperateurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchOperateurs.fulfilled, (state, action) => {
                state.isLoading = false
                state.operateurs = action.payload
            })
            .addCase(fetchOperateurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(createVente.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createVente.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createVente.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteVente.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteVente.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteVente.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchVentes = createAsyncThunk(
    'Ventes/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        const operateurs = payload?.type_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''

        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/ve.commande.load.details?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}&utilisateur_f=${operateurs}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch Ventes')
        }
    }
)


export const fetchOperateurs = createAsyncThunk(
    'nomsBanques/fetch',
    async () => {

        try {
            const response = await BaseService.get(
                `/XML/st.locaux.load/load_all_locaux_statut`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch banques')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsVentes = createAsyncThunk(
    'Ventes/fetchDetails',
    async (id) => {
        const data = { 'idc': id.id }
        try {
            const Vente = await BaseService.post(
                `/XML/ve.commande.load.details/get_one_commande`, data
            )
            const contenu = await BaseService.post(
                `/XML/ve.commande.load.details/get_details_one_commande`, data
            )
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`, { 'idcl': Vente.data?.[0]?.idcl }
            )
            let facture = {}

            if (Vente.data?.[0]?.id_facture) {

                facture = await BaseService.post(
                    `/XML/compta.factc.load/side_details_fact`, { 'idf': Vente.data?.[0]?.id_facture }
                )

            }
            const st_c = { 'BC': 'Bon de commande', 'F': 'Facture', 'D': 'Devi', 'AN': 'Annuler' }
            if (Vente.data[0]) {
                Vente.data[0]['statut_c'] = st_c[Vente.data[0]['statut_c']]
                Vente.data[0]['num'] = 'VE-' + Vente.data[0]['id']
                Vente.data[0]['mt_devis'] = formatToDH(Vente.data[0]['mt_devis'])
                Vente.data[0]['MT'] = formatToDH(Vente.data[0]['MT'])
                Vente.data[0]['utilisateur'] = Vente.data[0]['nom'] + ' ' + Vente.data[0]['prenom']
            }
            return { vente: Vente.data?.[0], contenu: contenu.data, Client: Client.data, facture: facture?.data }
        } catch (error) {
            throw new Error('Failed to fetch Ventes')
        }
    }
)


export const fetchContenuNLivreVente = createAsyncThunk(
    'Ventes/fetchcontenuNlivre',
    async (id) => {
        try {
            const response = await BaseService.post(`/XML/ve.commande.load.details/get_contenu_n_l_one_vente`, { 'idc': id })
            const Vente = await BaseService.post(
                `/XML/ve.commande.load.details/get_one_commande`, { 'idc': id }
            )
            return { contenuNLivre: response.data, vente: Vente.data?.[0] }
        } catch (error) {
            throw new Error('Failed to fetch Ventes')
        }
    }
)






export const createVente = createAsyncThunk(
    'Ventes/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/ve.commande.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Ventes/Ventes/add/' + response.data?.id)
            }
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            if (error.status === 409) {
                popNotification({
                    type: 'danger',
                    title: "Credit client dépassé",
                    duration: 3000,
                    message: "Credit client dépassé",
                })
                return
            }
            popNotification(creation?.error)
        }
    }
)


export const confVente = createAsyncThunk(
    'achats/conf',
    async (payload) => {
        const { data, setAded } = payload

        try {
            const response = await BaseService.post('/XML/ve.commande.send/confirmer_ddp', data)
            if (response.status === 200) {
                // resetForm()
                popNotification(creation?.success)
                setAded(true)
                // navigate('/achats')
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }

)

export const editVente = createAsyncThunk('Ventes/edit',
    async (payload) => {
        const { data, formik, setAded } = payload
        const { resetForm, setSubmitting } = formik

        try {
            const response = await BaseService.post('/XML/ve.commande.send/edit', data)
            if (response.status === 200) {

                setSubmitting(false)
                popNotification(creation?.success)
                setAded(true)
            }
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            if (error.status === 409) {
                popNotification({
                    type: 'danger',
                    title: "Credit client dépassé",
                    duration: 3000,
                    message: "Credit client dépassé",
                })
                return
            }
            popNotification(creation?.error)
        }
    }
)

export const deleteVente = createAsyncThunk(
    'Vente/delete',
    async (payload) => {
        const { data, setVenteAdded } = payload

        try {
            const response = await BaseService.post('/XML/ve.commande.send/delete', data)
            if (response.status === 200) {


                popNotification(creation?.success)
                setVenteAdded(true)
            }
        } catch (err) {
            console.log({ err })
            

            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.ventes.data
export const selectIsLoading = (state) => state.ventes.isLoading
export const selectError = (state) => state.ventes.error
export const selectIsDeleted = (state) => state.ventes.isDeleted
export const selectCurrentPage = (state) => state.ventes.currentPage
export const resetDetailsVente = VentesSlice.actions.resetDetailsVente
export const startLoading = VentesSlice.actions.startLoading
export const stopLoading = VentesSlice.actions.stopLoading
export default VentesSlice.reducer
