import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import moment from 'moment'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const fournisseursSlice = createSlice({
    name: 'fournisseurs',
    initialState: {
        data: [],
        detailsFournisseur: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsFournisseur: (state) => {
            if (state.detailsFournisseur == null) {
                state.detailsFournisseur = ''
            } else {
                state.detailsFournisseur = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsFournisseur = action.payload
            })
            .addCase(fetchDetailsFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createFournisseur.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createFournisseur.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(importFournisseur.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(importFournisseur.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(importFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
            .addCase(sendmailFournisseur.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(sendmailFournisseur.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(sendmailFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchFournisseurs = createAsyncThunk(
    'fournisseurs/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/ap.fr.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch fournisseurs')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsFournisseurs = createAsyncThunk(
    'Fournisseurs/fetchDetails',
    async (id) => {
        const data = { 'idfr': id.id }
        let date_d_f = id?.date_d_f || moment()
        let date_f_f = id?.date_f_f || moment().subtract(1, 'years')
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const promises = [
                BaseService.post(`/XML/ap.fr.load/get_one_fr`, data),
                BaseService.post(`/XML/ap.fr.load/get_contact`, data),
                BaseService.get(`/XML/compta.factf.load/detailsOneFournisseur?idfr=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/ap.co.load/detailsOneFournisseur?idfr=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/tr.ba.load/detailsOneFournisseur?idfr=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/tr.ec.fr.load/OneFr?idfr=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/st.reception.retour.load/oneFournisseur?idfr=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`)
            ];

            const results = await Promise.allSettled(promises);

            const Fournisseur = results[0].status === 'fulfilled' ? results[0].value.data : null;
            const contact = results[1].status === 'fulfilled' ? results[1].value.data : null;
            const facture = results[2].status === 'fulfilled' ? results[2].value.data : null;
            const achats = results[3].status === 'fulfilled' ? results[3].value.data : null;
            const decaissement = results[4].status === 'fulfilled' ? results[4].value.data : null;
            const effets = results[5].status === 'fulfilled' ? results[5].value.data : null;
            const retour = results[6].status === 'fulfilled' ? results[6].value.data : null;

            return { Fournisseur, contact, facture, achats, decaissement, effets,retour };
        } catch (error) {
            throw new Error('Failed to fetch Fournisseurs');
        }
    }
)
export const createFournisseur = createAsyncThunk(
    'fournisseurs/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/ap.fr.send/dataEnregistrer', data)
            if (data.contact != '') {
                await BaseService.post(`/XML/ap.fr.send/dataAddcontactfr`, { 'idfrcontact': response.data.idfr, 'nom': data.RS, 'fonc': '', 'email': data.E_mail, 'tel': data.contact })
            }
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log(err)
            popNotification(creation?.error)
        }
    }
)

export const editFournisseur = createAsyncThunk('fournisseurs/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ap.fr.send/dataModifier`, data)
        if (data.contact != '') {
            await BaseService.post(`/XML/ap.fr.send/dataUpdatecontactfr`, { 'idfrcontact': data.id, 'nom': data.RS, 'fonc': '', 'email': data.E_mail, 'tel': data.contact })
        }
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const editSupFournisseur = createAsyncThunk('fournisseurs/editSup', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ap.fr.send/m_sup`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const addContactFournisseur = createAsyncThunk('fournisseur/addContact', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ap.fr.send/dataAddcontactfr`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})











export const importFournisseur = createAsyncThunk(
    'fournisseur/import',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/ap.fr.send/uploadcsv`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)


export const sendmailFournisseur = createAsyncThunk(
    'fournisseur/mail',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/ap.fr.mail`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)

export const selectData = (state) => state.fournisseurs.data
export const selectIsLoading = (state) => state.fournisseurs.isLoading
export const selectError = (state) => state.fournisseurs.error
export const selectIsDeleted = (state) => state.fournisseurs.isDeleted
export const selectCurrentPage = (state) => state.fournisseurs.currentPage
export const resetDetailsFournisseur = fournisseursSlice.actions.resetDetailsFournisseur
export const startLoading = fournisseursSlice.actions.startLoading
export const stopLoading = fournisseursSlice.actions.stopLoading
export default fournisseursSlice.reducer
