import { THEME_ENUM } from 'constants/theme.constant'
import deepParseJson from 'utils/deepParseJson'
/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */
export async function getThemeConfig() {

  let uiConfig = localStorage.getItem('uiConfig');
  if(!uiConfig) {
  localStorage.setItem('uiConfig', JSON.stringify({
    "themeColor": "orange",
    "direction": "ltr",
    "mode": "light",
    "primaryColorLevel": 600,
    "cardBordered": false,
    "panelExpand": false,
    "controlSize": "md",
    "navMode": "themed",
    "layout": {
      "type": "stackedSide",
      "sideNavCollapse": false
    }
  }));
  uiConfig = localStorage.getItem('uiConfig')
  }
    return deepParseJson(uiConfig)
  

}


export const themeConfig = {
  "themeColor": "orange",
  "direction": "ltr",
  "mode": "light",
  "primaryColorLevel": 600,
  "cardBordered": false,
  "panelExpand": false,
  "controlSize": "md",
  "navMode": "themed",
  "layout": {
    "type": "stackedSide",
    "sideNavCollapse": false
  }
}