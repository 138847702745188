import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const listesSlice = createSlice({
    name: 'listes',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsListes.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsListes.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsListe = action.payload
        })
        .addCase(fetchDetailsListes.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchListes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchListes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchListes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createListe.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createListe.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createListe.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteContactListe.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteContactListe.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteContactListe.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })


            .addCase(sendmailListe.pending, (state) => {
                state.isLoading = true
            })
            .addCase(sendmailListe.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(sendmailListe.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchListes = createAsyncThunk(
    'listes/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/crm.list.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch listes')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsListes = createAsyncThunk(
    'Listes/fetchDetails',
    async (id) => {
        const data = {'id':id.id}
        try {
            const Liste = await BaseService.post(
                `/XML/crm.list.load/get_one_mail_list`,data
            )
            

            return Liste.data
        } catch (error) {
            throw new Error('Failed to fetch Listes')
        }
    }
)
export const createListe = createAsyncThunk(
    'listes/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
      
        try {
            const response = await BaseService.post('/XML/crm.list.send/ajouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const addContactListe = createAsyncThunk(
    'listesContact/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
      
        try {
            const response = await BaseService.post('/XML/crm.list.send/ajouter_contact_list', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const editListe = createAsyncThunk('listes/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/listes/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/listes')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteContactListe = createAsyncThunk(
    'liste/delete',
    async (payload) => {
        const { data, setContactDeleted } = payload
    
      
        try {
            const response = await BaseService.post('/XML/crm.list.send/remove_contact', data)
            if (response.status === 200) {
                
               
                popNotification(creation?.success)
                setContactDeleted(true)
            }
        } catch (err) {
            console.log({ err })
            
           
            
            popNotification(creation?.error)
        }
    }
)




export const sendmailListe = createAsyncThunk(
    'liste/mail',
    async (payload) => {
        const { data, formik, navigate,setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/crm.list.send/mail`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)
export const selectData = (state) => state.listes.data
export const selectIsLoading = (state) => state.listes.isLoading
export const selectError = (state) => state.listes.error
export const selectIsDeleted = (state) => state.listes.isDeleted
export const selectCurrentPage = (state) => state.listes.currentPage

export default listesSlice.reducer
