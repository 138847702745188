import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const receptionsRetoursSlice = createSlice({
    name: 'receptionsRetours',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsReceptionsRetours.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsReceptionsRetours.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.data = action.payload
        })
        .addCase(fetchDetailsReceptionsRetours.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchReceptionsRetours.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchReceptionsRetours.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchReceptionsRetours.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createReceptionsRetour.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createReceptionsRetour.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createReceptionsRetour.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteReceptionsRetour.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteReceptionsRetour.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteReceptionsRetour.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchReceptionsRetours = createAsyncThunk(
    'receptionsRetours/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.reception.retour.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch receptionsRetours')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsReceptionsRetours = createAsyncThunk(
    'ReceptionsRetours/fetchDetails',
    async (id) => {
        const data = {'idr':id.id}
        try {
            const ReceptionsRetour = await BaseService.post(
                `/XML/st.reception.retour.load/get_one_retour`,data
            )
            const contenue = await BaseService.post(
                `/XML/st.reception.retour.load/get_contenu_one_retour`,data
            )
            let idfr = ReceptionsRetour.data[0]?.['idfr'] || ReceptionsRetour.data[0]?.['idfournisseur']
            const Fournisseurs = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`,{'idfr':idfr}
            )
            if (ReceptionsRetour.data[0]) {
                ReceptionsRetour.data[0]['id'] = 'RR-'+ReceptionsRetour.data[0]['id']
                ReceptionsRetour.data[0]['id_source'] = 'R-'+ReceptionsRetour.data[0]['id_source']
                ReceptionsRetour.data[0]['utilisateur'] = ReceptionsRetour.data[0]['nom']+' '+ReceptionsRetour.data[0]['prenom']
            }
           
            return {ReceptionsRetour: ReceptionsRetour.data, contenue: contenue.data, Fournisseurs: Fournisseurs?.data}
        } catch (error) {
            throw new Error('Failed to fetch ReceptionsRetours')
        }
    }
)
export const createReceptionsRetour = createAsyncThunk(
    'receptionsRetours/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.reception.retour.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editReceptionsRetour = createAsyncThunk('receptionsRetours/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/receptionsRetours/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/receptionsRetours')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteReceptionsRetour = createAsyncThunk(
    'receptionsRetour/delete',
    async (payload) => {
        const { id,setreceptionsRetoursAded } = payload
        try {
            const response = await BaseService.post('/XML/st.reception.retour.send/delete', {id})
            if (response.status === 200) {
                popNotification(creation?.success)
                setreceptionsRetoursAded(true)
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.receptionsRetours.data
export const selectIsLoading = (state) => state.receptionsRetours.isLoading
export const selectError = (state) => state.receptionsRetours.error
export const selectIsDeleted = (state) => state.receptionsRetours.isDeleted
export const selectCurrentPage = (state) => state.receptionsRetours.currentPage

export default receptionsRetoursSlice.reducer
