import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import moment from 'moment'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const nonConformitesSlice = createSlice({
    name: 'nonConformites',
    initialState: {
        data: [],
        details: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetails: (state) => {
            if (state.details == null) {
                state.details = ''
            } else {
                state.details = null
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsNonConformites.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsNonConformites.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.details = action.payload
            })
            .addCase(fetchDetailsNonConformites.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchNonConformites.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchNonConformites.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchNonConformites.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createNonConformite.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createNonConformite.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createNonConformite.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteNonConformite.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteNonConformite.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteNonConformite.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchNonConformites = createAsyncThunk(
    'nonConformites/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/qualite.nonConformite.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch nonConformites')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsNonConformites = createAsyncThunk(
    'NonConformites/fetchDetails',
    async (id) => {
        const data = id.id
        try {
            const NonConformite = await BaseService.get(
                `/XML/qualite.nonConformite.load/details?id=${data}`
            )

            NonConformite.data.nonConformite.date = moment(NonConformite.data.nonConformite.date).format('DD/MM/YYYY')
            NonConformite.data.nonConformite.ordre_production = 'OP-'+NonConformite.data.nonConformite.id_ordre
            NonConformite.data.nonConformite.nomStandard = NonConformite.data.standards.nom
            
            return NonConformite.data
        } catch (error) {
            throw new Error('Failed to fetch NonConformites')
        }
    }
)
export const createNonConformite = createAsyncThunk(
    'nonConformites/post',
    async (payload) => {
        const { data, formik, navigate,setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/qualite.nonConformite.send/confirmer', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editNonConformite = createAsyncThunk('nonConformites/edit',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/qualite.nonConformite.send/edit', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Qualite/NonConformites')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    })

export const deleteNonConformite = createAsyncThunk(
    'nonConformite/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/nonConformites/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.nonConformites.data
export const selectIsLoading = (state) => state.nonConformites.isLoading
export const selectError = (state) => state.nonConformites.error
export const selectIsDeleted = (state) => state.nonConformites.isDeleted
export const selectCurrentPage = (state) => state.nonConformites.currentPage
export const resetDetails = nonConformitesSlice.actions.resetDetails
export default nonConformitesSlice.reducer
