import Decimal from "decimal.js";

function groupByPrefix(arr, length, plan) {
    const result = {};

    arr.forEach(item => {
        const key = item.compte.substring(0, length);
       
        if (!result[key]) {
            result[key] = { 
                compte: key, 
                description: plan[key] ? `${key} ${plan[key]}` : key,
                soldeDebutPeriode: new Decimal(0),
                soldeFinPeriode: new Decimal(0),
                mouvement: new Decimal(0),
                type:'balance',
                subRows: [] 
            };
        }
        
        result[key].soldeDebutPeriode = result[key].soldeDebutPeriode.plus(new Decimal(item?.soldeDebutPeriode || 0));
        result[key].soldeFinPeriode = result[key].soldeFinPeriode.plus(new Decimal(item.soldeFinPeriode || 0));
        result[key].subRows.push(item);
    });

    for (let key in result) {
        result[key].soldeDebutPeriode = result[key].soldeDebutPeriode.toFixed(2);
        result[key].soldeFinPeriode = result[key].soldeFinPeriode.toFixed(2);
        result[key].mouvement = new Decimal(result?.[key]?.soldeFinPeriode || 0).minus(result?.[key]?.soldeDebutPeriode || 0).toFixed(2);
    }

    return Object.values(result);
}

export function hierarchicalGroupBalance(data, plan, depth = 1) {
  
    if (depth > 4) return data;

    const grouped = groupByPrefix(data, depth, plan);
    grouped.forEach(group => {
        group.subRows = hierarchicalGroupBalance(group.subRows, plan, depth + 1);
    });

    return grouped.filter(group => group.subRows.length > 0);
}
