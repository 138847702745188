import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const avoirsClientsSlice = createSlice({
    name: 'avoirsClients',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsAvoirsClients.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsAvoirsClients.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.data = action.payload
        })
        .addCase(fetchDetailsAvoirsClients.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchAvoirsClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchAvoirsClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchAvoirsClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(payAvoirsClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(payAvoirsClient.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(payAvoirsClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteAvoirsClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteAvoirsClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteAvoirsClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchAvoirsClients = createAsyncThunk(
    'avoirsClients/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/compta.avoir_c.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch avoirsClients')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsAvoirsClients = createAsyncThunk(
    'AvoirsClients/fetchDetails',
    async (id) => {
        const data = {'idf':id.id}
        try {const lbl = {'lbl':'FAC_A_VEN_'+id.id}
            const AvoirsClient = await BaseService.post(
                `/XML/compta.avoir_c.load/calc_avoir_c_by_id`,data
            )
            const journal = await BaseService.post(
                `/XML/compta.jr.load/get_journal_by_lbl_av`,lbl
            )
const Client =  await BaseService.post(
    `/XML/ve.cl.load/get_one_cl`,{'idcl':AvoirsClient.data.idcl}
)

            return {'avoir':AvoirsClient.data,'journal':journal.data,'Client':Client.data}
        } catch (error) {
            throw new Error('Failed to fetch AvoirsClients')
        }
    }
)
export const payAvoirsClient = createAsyncThunk(
    'avoirsClients/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        
        try {
           
           const type = data?.transactions?.[0]?.type
           const compte = data?.transactions?.[0]?.compte.split('_')[1]
            const mt = data?.transactions?.[0]?.mt
            const ref = data?.transactions?.[0]?.ref
            const idfact = data?.idfact
            const date_valeur = data?.transactions?.[0]?.date_valeur
            const response = await BaseService.post('/XML/tr.ba.send/remb_avoir_c', {type:type,compte:compte,mt:mt,ref:ref,idfact:idfact,date_valeur:date_valeur})
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/comptabilite/AvoirsClients')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editAvoirsClient = createAsyncThunk('avoirsClients/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/avoirsClients/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/avoirsClients')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteAvoirsClient = createAsyncThunk(
    'avoirsClient/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/avoirsClients/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.avoirsClients.data
export const selectIsLoading = (state) => state.avoirsClients.isLoading
export const selectError = (state) => state.avoirsClients.error
export const selectIsDeleted = (state) => state.avoirsClients.isDeleted
export const selectCurrentPage = (state) => state.avoirsClients.currentPage

export default avoirsClientsSlice.reducer
