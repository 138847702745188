import {
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    CONFLICT_EMAIL_MESSAGE,
    TYPES,
} from 'constants/notifications.constant'
import { feedbackTitleHelper } from 'utils/feedbackTitleHelper'

const { addTitle, editTitle, deleteTitle } = feedbackTitleHelper('des Decomptes')
const { SUCCESS, ERROR } = TYPES

export const notificationConfig = {
    creation: {
        success: {
            type: SUCCESS,
            title: addTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: addTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
        conflict: {
            type: ERROR,
            title: addTitle,
            duration: 3000,
            message: CONFLICT_EMAIL_MESSAGE,
        },
    },
    edit: {
        success: {
            type: SUCCESS,
            title: editTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: editTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
        conflict: {
            type: ERROR,
            title: editTitle,
            duration: 3000,
            message: CONFLICT_EMAIL_MESSAGE,
        },
    },
    deletion: {
        success: {
            type: SUCCESS,
            title: deleteTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: deleteTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
    },
}
