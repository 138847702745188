import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import moment from 'moment'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsClient: (state) => {
            if (state.detailsClient == null) {
                state.detailsClient = ''
            } else {
                state.detailsClient = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDetailsClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsClient = action.payload
            })
            .addCase(fetchDetailsClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createClient.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(importClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(importClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(importClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })


            .addCase(sendmailClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(sendmailClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(sendmailClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })



            .addCase(ListePrix.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(ListePrix.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(ListePrix.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchClients = createAsyncThunk(
    'clients/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/ve.cl.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch clients')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsClients = createAsyncThunk(
    'clients/fetchDetails',
    async (id) => {

        const data = { 'idcl': id.id }
        let date_d_f = id?.date_d_f || moment()
        let date_f_f = id?.date_f_f || moment().subtract(1, 'years')
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const promises = [
                BaseService.post(`/XML/ve.cl.load/get_one_cl`, data),
                BaseService.post(`/XML/ve.cl.load/get_contact`, data),
                BaseService.get(`/XML/compta.factc.load/detailsOneClient?idcl=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/ve.commande.load.details/detailsOneClient?idcl=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/tr.ec.cl.load/OneCl?idcl=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/tr.ba.load/detailsOneClient?idcl=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`),
                BaseService.get(`/XML/st.livraison.retour.load/oneClient?idcl=${id.id}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`)
            ];

            const results = await Promise.allSettled(promises);

            const client = results[0].status === 'fulfilled' ? results[0].value.data : null;
            const contact = results[1].status === 'fulfilled' ? results[1].value.data : null;
            const facture = results[2].status === 'fulfilled' ? results[2].value.data : null;
            const ventes = results[3].status === 'fulfilled' ? results[3].value.data : null;
            const effets = results[4].status === 'fulfilled' ? results[4].value.data : null;
            const encaissements = results[5].status === 'fulfilled' ? results[5].value.data : null;
            const retour = results[6].status === 'fulfilled' ? results[6].value.data : null;

            return { client, contact, facture, ventes, effets, encaissements, retour };
        } catch (error) {
            throw new Error('Failed to fetch clients');
        }
    }
)

export const createClient = createAsyncThunk(
    'clients/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/ve.cl.send/dataEnregistrer', data)
            if (data.contact != '') {
                await BaseService.post(`/XML/ve.cl.send/dataAddcontactcl`, { 'idclcontact': response.data.idcl, 'nom': data.RS, 'fonc': '', 'email': data.E_mail, 'tel': data.contact })
            }
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editClient = createAsyncThunk('clients/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ve.cl.send/dataModifier`, data)

        if (data.contact != '') {
            await BaseService.post(`/XML/ve.cl.send/dataUpdatecontactcl`, { 'idclcontact': data.id, 'nom': data.RS, 'fonc': '', 'email': data.E_mail, 'tel': data.contact })
        }
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const editSupClient = createAsyncThunk('clients/editSup', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ve.cl.send/m_sup`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const addContactClient = createAsyncThunk('clients/addContact', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post(`/XML/ve.cl.send/dataAddcontactcl`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const importClient = createAsyncThunk(
    'client/import',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/ve.cl.send/uploadcsv`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)




export const sendmailClient = createAsyncThunk(
    'client/mail',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/ve.cl.mail`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)





export const ListePrix = createAsyncThunk(
    'client/ListePrix',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/ve.cl.send/listePrix`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)

export const selectData = (state) => state.clients.data
export const selectIsLoading = (state) => state.clients.isLoading
export const selectError = (state) => state.clients.error
export const selectIsDeleted = (state) => state.clients.isDeleted
export const selectCurrentPage = (state) => state.clients.currentPage
export const { resetDetailsClient, startLoading, stopLoading } = clientsSlice.actions;
export default clientsSlice.reducer
