import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const immobilisationsAquisesSlice = createSlice({
    name: 'immobilisationsAquises',
    initialState: {
        data: [],
        detailsImmobilisationsAquise: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsImmobilisationsAquises: (state) => {

            if (state.detailsImmobilisationsAquise == null) {
                state.detailsImmobilisationsAquise = ''
            } else {
                state.detailsImmobilisationsAquise = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsImmobilisationsAquises.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsImmobilisationsAquises.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsImmobilisationsAquise = action.payload
            })
            .addCase(fetchDetailsImmobilisationsAquises.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchImmobilisationsAquises.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchImmobilisationsAquises.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchImmobilisationsAquises.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createImmobilisationsAquise.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createImmobilisationsAquise.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createImmobilisationsAquise.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteImmobilisationsAquise.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteImmobilisationsAquise.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteImmobilisationsAquise.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchImmobilisationsAquises = createAsyncThunk(
    'immobilisationsAquises/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/im.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch immobilisationsAquises')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsImmobilisationsAquises = createAsyncThunk(
    'ImmobilisationsAquises/fetchDetails',
    async (id) => {
        const data = { 'idim': id.id }
        try {
            const ImmobilisationsAquise = await BaseService.post(
                `/XML/im.load/get_one_immo`, data
            )

            const Fournisseur = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, { 'idfr': ImmobilisationsAquise.data?.immo?.[0]?.idfr }
            )
            if (ImmobilisationsAquise.data.immo[0]) {
                ImmobilisationsAquise.data.immo[0]['operateur'] = ImmobilisationsAquise.data.immo[0]['nom_utilisateur'] + ' ' + ImmobilisationsAquise.data.immo[0]['prenom']
            }

           
            

            return { ImmobilisationsAquise: ImmobilisationsAquise.data, Fournisseur: Fournisseur.data }
        } catch (error) {
            console.log(error)
            throw new Error('Failed to fetch ImmobilisationsAquises')
        }
    }
)




export const createImmobilisationsAquise = createAsyncThunk(
    'immobilisationsAquises/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/im.send/ajouter_immo', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const cederImmobilisationsAquise = createAsyncThunk(
    'immobilisationsAquises/deder',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/imv.send/cession_immo', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const deleteImmobilisationsAquise = createAsyncThunk(
    'immobilisationsAquises/ceder',
    async (payload) => {
        const { id, setImmobilisationsAquiseAdded } = payload

        try {
            const response = await BaseService.post('/XML/im.send/delete', { 'id': id })
            if (response.status === 200) {

                popNotification(creation?.success)
                setImmobilisationsAquiseAdded(true)
            }
        } catch (err) {
            console.log({ err })
            

            
            popNotification(creation?.error)
        }
    }
)
export const editImmobilisationsAquise = createAsyncThunk('immobilisationsAquises/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/immobilisationsAquises/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/immobilisationsAquises')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const selectData = (state) => state.immobilisationsAquises.data
export const selectIsLoading = (state) => state.immobilisationsAquises.isLoading
export const selectError = (state) => state.immobilisationsAquises.error
export const selectIsDeleted = (state) => state.immobilisationsAquises.isDeleted
export const selectCurrentPage = (state) => state.immobilisationsAquises.currentPage
export const resetDetailsImmobilisationsAquises = immobilisationsAquisesSlice.actions.resetDetailsImmobilisationsAquises
export default immobilisationsAquisesSlice.reducer
