import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const supportsSlice = createSlice({
    name: 'supports',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsSupports: (state) => {
            if (state.detailsSupports == null) {
                state.detailsSupports = ''
            } else {
                state.detailsSupports = null
            }
        },
        setDetailsSupports: (state, data) => {
            state.detailsSupports = data.payload
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsSupports.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsSupports.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsSupports = action.payload
        })
        .addCase(fetchDetailsSupports.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchSupports.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchSupports.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchSupports.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createSupport.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createSupport.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createSupport.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })

    },
})

// Async thunk action to fetch data from the API
export const fetchSupports = createAsyncThunk(
    'supports/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/support.tickets.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch supports')
        }
    }
)

export const createSupport = createAsyncThunk(
    'supports/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/support.tickets.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(`/SUPPORT/ticket/${response.data.id}`)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsSupports = createAsyncThunk(
    'Supports/fetchDetails',
    async (id) => {
        try {
            const Support = await BaseService.get(
                `/XML/support.tickets.load/details?id=${id.id}`
            )
            

            return Support.data
        } catch (error) {
            throw new Error('Failed to fetch Supports')
        }
    }
)


export const commentSupport = createAsyncThunk(
    'supports/comment',
    async (payload) => {
        const { id, comment, setCommentAded } = payload
        try {
            const response = await BaseService.post('/XML/support.tickets.send/comment', {comment:comment,id:id})
            if (response.status === 200) {
                
             
                popNotification(creation?.success)
                setCommentAded(true)
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }
)

export const closeTicket = createAsyncThunk(
    'supports/comment',
    async (payload) => {
        const { id, setSupportAdded } = payload
        try {
            const response = await BaseService.post('/XML/support.tickets.send/close', {id:id})
            if (response.status === 200) {
                
             
                popNotification(creation?.success)
                setSupportAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }
)


export const selectData = (state) => state.supports.data
export const selectIsLoading = (state) => state.supports.isLoading
export const selectError = (state) => state.supports.error
export const selectIsDeleted = (state) => state.supports.isDeleted
export const selectCurrentPage = (state) => state.supports.currentPage
export const resetDetailsSupports = supportsSlice.actions.resetDetailsSupports
export const setDetailsSupports = supportsSlice.actions.setDetailsSupports
export const startLoading = supportsSlice.actions.startLoading
export const stopLoading = supportsSlice.actions.stopLoading
export default supportsSlice.reducer
