import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const livraisonsSlice = createSlice({
    name: 'livraisons',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsLivraisons.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsLivraisons.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsLivraisons = action.payload
        })
        .addCase(fetchDetailsLivraisons.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchLivraisons.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchLivraisons.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchLivraisons.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createLivraison.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createLivraison.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createLivraison.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteLivraison.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteLivraison.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteLivraison.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchLivraisons = createAsyncThunk(
    'livraisons/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.livraison.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch livraisons')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsLivraisons = createAsyncThunk(
    'Livraisons/fetchDetails',
    async (id) => {
        const data = {'idr':id.id}
        try {
            const Livraison = await BaseService.post(
                `/XML/st.livraison.load/get_one_livraison`,data
            )
            const content = await BaseService.post(
                `/XML/st.livraison.load/get_contenu_one_livraison`,data
            )
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`,{'idcl':Livraison.data?.[0]?.idcl}
            )
            if (Livraison.data[0]) {
                
                Livraison.data[0]['utilisateur'] = Livraison.data[0]['nom'] + ' ' + Livraison.data[0]['prenom']
                Livraison.data[0]['id_co'] = ' VE-' + Livraison.data[0]['id_co']
            }
            return {'livraison':Livraison.data,'content':content.data,'client':Client.data}
        } catch (error) {
            throw new Error('Failed to fetch Livraisons')
        }
    }
)
export const createLivraison = createAsyncThunk(
    'livraisons/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.livraison.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editLivraison = createAsyncThunk('livraisons/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/livraisons/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/livraisons')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteLivraison = createAsyncThunk(
    'livraison/delete',
    async (payload) => {
        const { id, setLivraisonAdded } = payload
             
             try {
                 const response = await BaseService.post('/XML/st.livraison.send/delete', {'id':id})
                 if (response.status === 200) {
                    
                     popNotification(creation?.success)
                     setLivraisonAdded(true)
                 }
             } catch (err) {
                 console.log({ err })
                 
                 popNotification(creation?.error)
             }
         }
)

export const selectData = (state) => state.livraisons.data
export const selectIsLoading = (state) => state.livraisons.isLoading
export const selectError = (state) => state.livraisons.error
export const selectIsDeleted = (state) => state.livraisons.isDeleted
export const selectCurrentPage = (state) => state.livraisons.currentPage
export const startLoading = livraisonsSlice.actions.startLoading
export const stopLoading = livraisonsSlice.actions.stopLoading

export default livraisonsSlice.reducer
