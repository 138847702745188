import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const configsSlice = createSlice({
    name: 'configs',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetConfigs: (state) => {
            if(state.detailsConf == null){
                state.detailsConf = ''
            }else{
                state.detailsConf = null
            }}
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsConfigs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsConfigs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsConf = action.payload
            })
            .addCase(fetchDetailsConfigs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchConfigs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchConfigs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchConfigs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createConfig.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createConfig.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createConfig.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteConfig.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteConfig.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteConfig.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchConfigs = createAsyncThunk(
    'configs/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.ba.load/get_conf_tr?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch configs')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsConfigs = createAsyncThunk(
    'Configs/fetchDetails',
    async (id) => {
    
        const data = { 'id': id }
        try {
            const Config = await BaseService.post(
                `/XML/tr.ba.load/get_details_conf`, data
            )
           

            return {'config':Config.data}
        } catch (error) {
            throw new Error('Failed to fetch Configs')
        }
    }
)

export const createConfig = createAsyncThunk(
    'configs/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ba.send/config/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Tresorerie/Configurations')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editConfig = createAsyncThunk('configs/edit', 
async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/tr.ba.send/config/edit', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            navigate('/Tresorerie/Configurations')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
})

export const deleteConfig = createAsyncThunk(
    'config/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/configs/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)


export const selectData = (state) => state.configs.data
export const selectIsLoading = (state) => state.configs.isLoading
export const selectError = (state) => state.configs.error
export const selectIsDeleted = (state) => state.configs.isDeleted
export const selectCurrentPage = (state) => state.configs.currentPage
export const resetConfigs = configsSlice.actions.resetConfigs
export default configsSlice.reducer
