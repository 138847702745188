import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { notificationConfig } from './constants'
import { formatDateTime }  from '../../functions/formatDate'

// Redux Toolkit slice
const statistiquesProductionsSlice = createSlice({
    name: 'statistiquessctions',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatistiquesProductions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchStatistiquesProductions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchStatistiquesProductions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            
    },
})

// Async thunk action to fetch data from the API
export const fetchStatistiquesProductions = createAsyncThunk(
    'statistiquesProductions/fetch',
    async ({ startDate, endDate }) => {
        
        // Check for existence of startDate and endDate
        if (!startDate || !endDate) {
            throw new Error('Missing startDate or endDate');
        }
        
        try {
            const MP = await BaseService.get(
                `/XML/st.stat.load/reception/cout?categorie=MP&startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`
            )
            const PF = await BaseService.get(
                `/XML/st.stat.load/reception/cout?categorie=PF&startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`
            )
            const production = await BaseService.get(
                `/XML/pr.ordre_production.load/stat?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`
            )
            return {'MP':MP.data,'PF':PF.data,'production':production.data}
        } catch (error) {
            throw new Error('Failed to fetch statistiquesProductions')
        }
    }
)




export const selectData = (state) => state.statistiquesProductions.data
export const selectIsLoading = (state) => state.statistiquesProductions.isLoading
export const selectError = (state) => state.statistiquesProductions.error

export default statistiquesProductionsSlice.reducer
