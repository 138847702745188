import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { notificationConfig } from './constants'
import { formatDateTime }  from '../../functions/formatDate'

// Redux Toolkit slice
const statistiquesAchatsSlice = createSlice({
    name: 'statistiquesAchats',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatistiquesAchats.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchStatistiquesAchats.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchStatistiquesAchats.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            
    },
})

// Async thunk action to fetch data from the API
export const fetchStatistiquesAchats = createAsyncThunk(
    'statistiquesAchats/fetch',
    async ({ startDate, endDate }) => {
        
        // Check for existence of startDate and endDate
        if (!startDate || !endDate) {
            throw new Error('Missing startDate or endDate');
        }
        
        try {
            const response = await BaseService.get(
                `/XML/ap.stat.load?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch statistiquesAchats')
        }
    }
)




export const selectData = (state) => state.statistiquesAchats.data
export const selectIsLoading = (state) => state.statistiquesAchats.isLoading
export const selectError = (state) => state.statistiquesAchats.error

export default statistiquesAchatsSlice.reducer
