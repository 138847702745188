import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './st.locale/localeSlice'
import selectedAction from './selectedAction/selectedActionSlice'
import clients from './ve.clients/clientsSlice'
import ventes from './ve.ventes/ventesSlice'
import fournisseurs from './ap.fournisseurs/fournisseursSlice'
import achats from './ap.achats/achatsSlice'
import marches from './marche.marches/marchesSlice'
import decomptes from './marche.decomptes/decomptesSlice'
import dossiers from './chantier.dossiers/dossiersSlice'
import livraisons from './st.livraisons/livraisonsSlice'
import receptions from './st.receptions/receptionsSlice'
import stocks from './st.stocks/stocksSlice'
import articles from './st.articles/articlesSlice'
import nomenclatures from './pr.nomenclatures/nomenclaturesSlice'
import ordresProductions from './pr.ordresProductions/ordresProductionsSlice'
import equipements from './eq.equipements/equipementsSlice'
import demandesMaintenances from './eq.demandesMaintenances/demandesMaintenancesSlice'
import immobilisationsAquises from './im.immobilisationsAquises/immobilisationsAquisesSlice'
import immobilisationsCédées from './im.immobilisationsCédées/immobilisationsCédéesSlice'
import cheques from './tr.cheques/chequesSlice'
import chequesFr from './tr.ChequesFr/chequesFrSlice'
import effetsClients from './tr.effetsClients/effetsClientsSlice'
import effetsFournisseurs from './tr.effetsFournisseurs/effetsFournisseursSlice'
import recouvrements from './rh.recouvrements/recouvrementsSlice'
import autresPps from './tr.autresPps/autresPpsSlice'
import avoirsClients from './compta.avoirsClients/avoirsClientsSlice'
import avoirsFournisseurs from './compta.avoirsFournisseurs/avoirsFournisseursSlice'
import facturesClients from './compta.facturesClients/facturesClientsSlice'
import facturesFournisseurs from './compta.facturesFournisseurs/facturesFournisseursSlice'
import resultats from './compta.resultats/resultatsSlice'
import budgets from './compta.budgets/budgetsSlice'
import tvas from './compta.tvas/tvasSlice'
import journals from './compta.journals/journalsSlice'
import conges from './rh.conges/congesSlice'
import employes from './rh.employes/employesSlice'
import paies from './rh.paies/paiesSlice'
import postes from './rh.postes/postesSlice'
import recrutements from './rh.recrutements/recrutementsSlice'
import opportunites from './crm.opportunites/opportunitesSlice'
import listes from './crm.listes/listesSlice'
import calendriers from './crm.calendriers/calendriersSlice'
import calendrierMaintenances from './eq.calendrierMaintenances/calendrierMaintenancesSlice'
import statistiquesAchats from './ap.statistiquesAchats/statistiquesAchatsSlice'
import statistiquesVentes from './ve.statistiquesVentes/statistiquesVentesSlice'
import statistiquesTresoreries from './tr.statistiquesTresoreries/statistiquesTresoreriesSlice'
import tableauBords from './tableauBords/tableauBordsSlice'
import livraisonsRetours from './st.livraisonsRetours/livraisonsRetoursSlice'
import receptionsRetours from './rh.receptionsRetours/receptionsRetoursSlice'
import locauxs from './st.locauxs/locauxsSlice'
import banques from './tr.banques/banquesSlice'
import balances from './tr.balances/balancesSlice'
import configs from './tr.configs/configsSlice'
import etats from './compta.etats/etatsSlice'
import paiesComptas from './rh.paiesComptas/paiesComptasSlice'
import globales from './globales/globalesSlice'
import informations from './config.informations/informationsSlice'
import crms from './config.crms/crmsSlice'
import conditions from './config.conditions/conditionsSlice'
import utilisateurs from './utilisateurs/utilisateursSlice'
import standards from './qualite.standards/standardsSlice'
import tests from './qualite.tests/testsSlice'
import nonConformites from './qualite.nonConformites/nonConformitesSlice'
import statistiquesProductions from './pr.statistiquesProductions.js/statistiquesProductionsSlice'
import transferts from './st.transferts/transfertsSlice'
import supports from './supports/supportsSlice'
import articlesWebsite from './website.articles/articlesSlice'
import pagesWebsite from './website.pages/pagesSlice'
import categoriesArticleWebsite from './website.categoriesArticles/categoriesArticlesSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        selectedAction,
        clients,
        ventes,
        fournisseurs,
        achats,
        marches,
        decomptes,
        dossiers,
        livraisons,
        receptions,
        stocks,
        articles,
        nomenclatures,
        ordresProductions,
        equipements,
        demandesMaintenances,
        immobilisationsAquises,
        immobilisationsCédées,
        cheques,
        chequesFr,
        effetsClients,
        effetsFournisseurs,
        recouvrements,
        autresPps,
        avoirsClients,
        avoirsFournisseurs,
        facturesClients,
        facturesFournisseurs,
        resultats,
        budgets,
        tvas,
        journals,
        conges,
        employes,
        paies,
        postes,
        recrutements,
        opportunites,
        listes,
        calendriers,
        calendrierMaintenances,
        statistiquesAchats,
        statistiquesVentes,
        statistiquesTresoreries,
        tableauBords,
        livraisonsRetours,
        receptionsRetours,
        locauxs,
        banques,
        etats,
        paiesComptas,
        globales,
        configs,
        balances,
        informations,
        crms,
        conditions,
        utilisateurs,
        standards,
        tests,
        nonConformites,
        statistiquesProductions,
        transferts,
        supports,
        articlesWebsite,
        categoriesArticleWebsite,
        pagesWebsite,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
