import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const facturesClientsSlice = createSlice({
    name: 'facturesClients',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsFacturesClient: (state) => {
            if (state.detailsFacturesClient == null) {
                state.detailsFacturesClient = ''
            } else {
                state.detailsFacturesClient = null
            }
        },
        resetDataCreateFacturesClients: (state) => {
            if (state.dataAdd == null) {
                state.dataAdd = ''
            } else {
                state.dataAdd = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchDataCreateFacturesClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDataCreateFacturesClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.dataAdd = action.payload
            })
            .addCase(fetchDataCreateFacturesClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(fetchDetailsFacturesClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsFacturesClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsFacturesClient = action.payload
            })
            .addCase(fetchDetailsFacturesClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchFacturesClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchFacturesClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchFacturesClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(fetchNumerotationAuto.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchNumerotationAuto.fulfilled, (state, action) => {
                state.isLoading = false
                state.numerotation = action.payload
            })
            .addCase(fetchNumerotationAuto.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })



            .addCase(editFacturesClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(editFacturesClient.fulfilled, (state, action) => {
                state.isLoading = false

            })
            .addCase(editFacturesClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(PayFacturesClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(PayFacturesClient.fulfilled, (state, action) => {
                state.isLoading = false

            })
            .addCase(PayFacturesClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })





            .addCase(createFacturesClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createFacturesClient.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createFacturesClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteFacturesClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteFacturesClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteFacturesClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchFacturesClients = createAsyncThunk(
    'facturesClients/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/compta.factc.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch facturesClients')
        }
    }
)



// Async thunk action to fetch data from the API
export const fetchNumerotationAuto = createAsyncThunk(
    'fetchNumerotationAuto/fetch',
    async () => {

        try {
            const numerotation = (await BaseService.post(
                `/XML/info/arr_info`, { 'arr_info': 'numAutoFactures' }
            ))

            return numerotation.data?.[0]?.contenu
        } catch (error) {
            throw new Error('Failed to fetch facturesClients')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsFacturesClients = createAsyncThunk(
    'FacturesClients/fetchDetails',
    async (id) => {
        try {
            const [FacturesClient, content, journal] = await Promise.all([
                BaseService.post(`/XML/compta.factc.load/side_details_fact`, { 'idf': id.id }),
                BaseService.post(`/XML/compta.factc.load/calc_fact_by_id`, { 'idf': id.id }),
                BaseService.post(`/XML/compta.jr.load/get_journal_by_lbl`, { 'lbl': 'FAC_D_VEN_' + id.id })
            ]);

            const Client = await BaseService.post(`/XML/ve.cl.load/get_one_cl`, { 'idcl': FacturesClient.data.fact[0].idcl });

            const reglement = (await BaseService.post(`/XML/compta.factc.load/get_reg_fact_c`, { 'lbl_int': 'REG_FAC_D_VEN_' + id.id })).data;

            const typesRegelement = [];
            const obj_pay = {
                'v': 'Virement',
                'esp': 'Espèce',
                'c': 'Chèque'
            };

            await Promise.all(reglement.map(async (element, index) => {
                const piece = element['lbl_piece'].split('__')[0];
                const id = element['lbl_piece'].split('__')[1];

                if (piece.startsWith('banque') || piece.startsWith('caisse')) {
                    const tr = (await BaseService.post('/XML/tr.ba.load/get_transaction_by_id', { 'compte': piece, 'id': id }))?.data?.[0];
                    typesRegelement.push({ type: obj_pay[tr['nature'].toLowerCase()], MT: tr['MT'], ref: tr['ref'], date: tr['dateo'] });
                } else if (piece.startsWith('cheque')) {
                    const tr = (await BaseService.post('/XML/tr.cheque.load/get_one_cheque', 'idc=' + id))?.data?.[0];
                    typesRegelement.push({ type: "Cheque", MT: tr['MT'], ref: tr['ref'], date: tr['datec'] });
                } else if (piece.startsWith('ec')) {
                    let t = 'c';
                    if (piece.split('_')[1] == 'c') {
                        t = 'd';
                    }
                    const tr = (await BaseService.post('/XML/tr.ec.load/get_one_ec', 'type_ec=' + t + '&idec=' + id))?.data?.[0];
                    typesRegelement.push({ type: "Effet de commerce", MT: tr['MT'], ref: tr['ref'], date: tr['date'] });
                } else if (piece.startsWith('Avoir')) {
                    const MT = reglement[index]['MT'];
                    typesRegelement.push({ type: "Avoir", MT: MT, ref: reglement[index]['ref'], date: reglement[index]['date'] });
                }
            }));

            return { 'facture': FacturesClient.data, 'content': content.data, 'journal': journal.data, 'Client': Client.data, typesRegelement: typesRegelement };
        } catch (error) {
            throw new Error('Failed to fetch FacturesClients');
        }
    }
);

export const fetchDataCreateFacturesClients = createAsyncThunk(
    'FacturesClients/fetchDetailsAdd',
    async ({ idcl, type, idCo }) => {
        try {
            const client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`, { 'idcl': idcl }
            )
            let commande = await BaseService.post(
                `/XML/ve.commande.load.details/get_one_commande`, { 'idc': idCo }
            )
            return { 'client': client.data, 'commande': commande.data }

        } catch (error) {
            throw new Error('Failed to fetch FacturesClients')
        }
    }
)
export const createFacturesClient = createAsyncThunk(
    'facturesClients/post',
    async (payload) => {
        const { data, formik, navigate, urlOrigine } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('XML/compta.factc.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)

                navigate(-1)

            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)


export const createAvoirsClient = createAsyncThunk(
    'avoirsClients/post',
    async (payload) => {
        const { data, formik, navigate, } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/compta.avoir_c.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)

                navigate('/comptabilite/FacturesClients')

            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)


export const PayFacturesClient = createAsyncThunk(
    'facturesClients/pay',
    async (payload) => {
        const { data, formik, navigate, urlOrigine } = payload
        const { resetForm, setSubmitting } = formik
        try {
            for (let i = 0; i < data?.transactions?.length; i++) {
                const element = data?.transactions[i];
                const type = element?.type
                const compte = element?.compte.split('_')[1]
                const mt = element?.MT
                const ref = element?.ref
                const idfact = data?.idfact
                const echeance = element?.date
                const date_valeur = element?.date_valeur
                const idavoir = element?.idavoir
                if (data?.typePay == 'newTr') {

                    if (type == 'esp' || type == 'vr' || type == 'ver' || type == 'p') {
                        await BaseService.post('/XML/tr.ba.send/pay_fact_c', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, date_valeur: date_valeur })
                    } else if (type == 'ec') {
                        await BaseService.post('/XML/tr.ec.send/pay_fact_c', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, echeance: echeance, date_valeur: date_valeur })
                    } else if (type == 'ch') {
                        await BaseService.post('/XML/tr.cheque.send/pay_fact_c', { type: type, compte: compte, mt: mt, ref: ref, idfact: idfact, echeance: echeance, date_valeur: date_valeur })
                    }
                } else if (data?.typePay == 'tr') {
                    await BaseService.post('/XML/tr.ba.send/lettre_fact_c', { ref: ref, idfact: idfact })
                } else if (data?.typePay == "av") {
                    await BaseService.post('/XML/compta.avoir_c.send/pay_fact_c', { id_fact: idfact, id_avoir: idavoir })
                } else if (data?.typePay == "ch") {
                    await BaseService.post('/XML/tr.cheque.send/lettre_fact_c', { cheque: ref, idfact: idfact })
                }else if (data?.typePay == "ec") {
                    await BaseService.post('/XML/tr.ec.send/lettre_fact', { ec: ref, idfact: idfact,type:'c' })
                }
            }
            //enregistrer gain ou perte de change
            await BaseService.post('XML/tr.ba.send/pay_fact_c/gainPerteChange', { id_fact: data?.idfact, gainChange: data?.gainChange, mtChange: data?.mtChange, date_valeur: data?.dateValeur, compte: data?.compteChange })

            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            navigate(-1)

        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editFacturesClient = createAsyncThunk('facturesClients/edit',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/compta.factc.send/modifier', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)

                setClientAdded(true)

            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    })

export const deleteFacturesClient = createAsyncThunk(
    'facturesClient/delete',
    async (payload) => {
        const { data, setFacturesClientAdded } = payload

        try {
            const response = await BaseService.post('XML/compta.factc.send/supprimer', data)
            if (response.status === 200) {

                popNotification(creation?.success)
                setFacturesClientAdded(true)

            }
        } catch (err) {
            console.log({ err })



            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.facturesClients.data
export const selectIsLoading = (state) => state.facturesClients.isLoading
export const selectError = (state) => state.facturesClients.error
export const selectIsDeleted = (state) => state.facturesClients.isDeleted
export const selectCurrentPage = (state) => state.facturesClients.currentPage
export const resetDetailsFacturesClient = (state) => state.facturesClients.detailsFacturesClient
export const resetDataCreateFacturesClients = facturesClientsSlice.actions.resetDataCreateFacturesClients
export const startLoading = facturesClientsSlice.actions.startLoading
export const stopLoading = facturesClientsSlice.actions.stopLoading
export default facturesClientsSlice.reducer
