import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const equipementsSlice = createSlice({
    name: 'equipements',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsEquipements: (state) => {
            if (state.detailsEquipement == null) {
                state.detailsEquipement = ''
            } else {
                state.detailsEquipement = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsEquipements.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsEquipements.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsEquipement = action.payload
            })
            .addCase(fetchDetailsEquipements.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchEquipements.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEquipements.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEquipements.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createEquipement.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEquipement.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEquipement.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteEquipement.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteEquipement.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteEquipement.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEquipements = createAsyncThunk(
    'equipements/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/main.eq.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch equipements')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsEquipements = createAsyncThunk(
    'Equipements/fetchDetails',
    async (id) => {
        let date_d_f = id?.date_d_f || dayjs()
        let date_f_f = id?.date_f_f || dayjs().subtract(1, 'years')
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        const data = {
            'id': id.id,
            'date_d_f': date_d_f,
            'date_f_f': date_f_f,
        }
        try {
            const Equipement = await BaseService.post(
                `/XML/main.eq.load/get_one_eq`, data
            )
            if (Equipement.data.machine[0] && typeof id.side !== 'undefined') {
                Equipement.data.machine[0]['date_mise_service'] = dayjs(Equipement.data.machine[0]['date_mise_service']).format('DD/MM/YYYY')
                Equipement.data.machine[0]['dure_vie_m'] = Equipement.data.machine[0]['date_mise_service'] + ' Mois'
                Equipement.data.machine[0]['cout_hm'] = formatToDH(Equipement.data.machine[0]['cout_hm'])
                Equipement.data.machine[0]['recurence_maintenance_j'] = Equipement.data.machine[0]['cout_hm'] + ' Jours'
            }

            return Equipement.data
        } catch (error) {
            throw new Error('Failed to fetch Equipements')
        }
    }
)

export const createEquipement = createAsyncThunk(
    'equipements/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/main.eq.send/ajouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editEquipement = createAsyncThunk('equipements/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/main.eq.send/modifier', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})

export const deleteEquipement = createAsyncThunk(
    'equipement/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/equipements/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.equipements.data
export const selectIsLoading = (state) => state.equipements.isLoading
export const selectError = (state) => state.equipements.error
export const selectIsDeleted = (state) => state.equipements.isDeleted
export const selectCurrentPage = (state) => state.equipements.currentPage
export const resetDetailsEquipements = equipementsSlice.actions.resetDetailsEquipements
export default equipementsSlice.reducer
