const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/access-denied',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    baseUrl: 'https://app.oceon.ma/connection',
    //  baseUrl: 'http://localhost:80/connection',
}

export default appConfig