import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  action: "",
  data: {},
};

export const selectedActionSlice = createSlice({
  name: "selected/action",
  initialState,
  reducers: {
    setAction: (state, { payload }) => {
      state.action = payload?.action;
      state.data = payload?.row;
    },
    resetAction: (state) => {
      state.action = "";
      state.data = {};
    },
  },
});

export const { setAction, resetAction } = selectedActionSlice.actions;

export const selectAction = (state) => state.selectedAction.action;
export const selectedActionData = (state) => state.selectedAction.data;
export default selectedActionSlice.reducer;
